import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {AtomicAngularModule} from '@coveo/atomic-angular';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FieldLabelComponent } from './field-label/field-label.component';
import { AtomicAngularPageComponent } from './atomic-angular-page/atomic-angular-page.component';
import { FieldValueComponent } from './field-value/field-value.component';
import { LabelAndFieldValueComponent } from './label-and-field-value/label-and-field-value.component';
import { EngineService } from "./engine.service";
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { RecomendationSectionComponent } from './recomendation-section/recomendation-section.component';
import { DataSourceSectionComponent } from './data-source-section/data-source-section.component';
import { ResultsPageHeaderComponent } from './shared/results-page-header/results-page-header.component';
import { ResultsPageFooterComponent } from './shared/results-page-footer/results-page-footer.component';
import { ResultsPageComponent } from './results-page/results-page.component';
import { ResultListComponent } from './result-list/result-list.component';
import { ResultButtonComponent } from './result-button/result-button.component';
import { QuickViewComponent } from './quick-view/quick-view.component';
import { CoveoModalComponent } from './coveo-modal/coveo-modal.component';
import { ViewpageComponent } from './viewpage/viewpage.component';
import { PopupComponent } from './shared/popup/popup.component';
import { MatDialogModule } from '@angular/material/dialog';
import { CsnPopupComponent } from './csn-popup/csn-popup.component';
import { DatePipe } from '@angular/common';
import { OneDriveRegistrationComponent } from './one-drive-registration/one-drive-registration.component';
import { HomeComponent } from './home/home.component';
import { FormsModule } from '@angular/forms';
import { ExportToExcelComponent } from './export-to-excel/export-to-excel.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { UsersettingsComponent } from './usersettings/usersettings.component';
import { CollectionsComponent } from './collections/collections.component';
import { SavedsearchComponent } from './savedsearch/savedsearch.component';
import { OnedriveComponent } from './onedrive/onedrive.component';
import { CsnShareComponent } from './csn-share/csn-share.component';
import { UserDeprovisioningComponent } from './user-deprovisioning/user-deprovisioning.component';
import { ErrorComponent } from './error/error.component';
import { OneDriveRegisterationMultipleComponent } from './one-drive-registeration-multiple/one-drive-registeration-multiple.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { ExternalUrlSupportComponent } from './external-url-support/external-url-support.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { VaultFileDownloadComponent } from './vault-file-download/vault-file-download.component';
import { RemovehtmlPipe } from './removehtml.pipe';
import { CustomdatePipe } from './customdate.pipe';
import { ConfigService } from './config.service';
import { AuthInterceptor } from './auth.interceptor';


@NgModule({
  declarations: [
    AppComponent,
    FieldLabelComponent,
    AtomicAngularPageComponent,
    FieldValueComponent,
    LabelAndFieldValueComponent,
    HeaderComponent,
    FooterComponent,
    RecomendationSectionComponent,
    DataSourceSectionComponent,
    ResultsPageHeaderComponent,
    ResultsPageFooterComponent,
    ResultsPageComponent,
    ResultListComponent,
    ResultButtonComponent,
    QuickViewComponent,
    CoveoModalComponent,
    ViewpageComponent,
    CsnPopupComponent,
    OneDriveRegistrationComponent,
    HomeComponent,
    ExportToExcelComponent,
    AuthenticationComponent,
    UsersettingsComponent,
    CollectionsComponent,
    SavedsearchComponent,
    OnedriveComponent,
    CsnShareComponent,
    UserDeprovisioningComponent,
    ErrorComponent,
    OneDriveRegisterationMultipleComponent,
    ConfirmationComponent,
    ExternalUrlSupportComponent,
    MaintenanceComponent,
    VaultFileDownloadComponent,
    RemovehtmlPipe,
    CustomdatePipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule, AtomicAngularModule, HttpClientModule,MatDialogModule, FormsModule,
  ],
  providers: 
  [EngineService,DatePipe,ConfigService,
    {
     provide: HTTP_INTERCEPTORS,
     useClass:AuthInterceptor,
     multi:true 
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
