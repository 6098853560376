import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';

import { SearchBox } from '@coveo/headless';
import { EngineService } from '../engine.service';
import { ENVIRONMENT,SYSTEM_MSGS } from 'src/environments/environment.development';
// import outage from "../../assets/merck-assets/outage_msg.json";

import outage from "../../assets/merck-assets/outage_msg.json"
 

import {

  SearchEngine,
  loadAdvancedSearchQueryActions
} from '@coveo/headless';

import {loadContextActions } from '@coveo/atomic-angular';
import { CookieServiceWrapper } from '../cookie-service-wrapper.service';
import { Customeventlogging } from '../customevent-logging.service';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfigService } from '../config.service';

 

@Component({

  selector: 'app-atomic-angular-page',

  templateUrl: './atomic-angular-page.component.html',

  styleUrls: ['./atomic-angular-page.component.css'],

})

export class AtomicAngularPageComponent implements AfterViewInit, OnInit {

  @ViewChild('searchInterface')

  searchInterface!: HTMLAtomicSearchInterfaceElement;

  mySearchBox?: SearchBox;

  private engine!: SearchEngine;
  outage1:any;
  outage2:any;
  isOutage = false;
 

  constructor(private engineService: EngineService, 
    private cookieService:CookieServiceWrapper,
    private eventLoggingService: Customeventlogging,
    private configService : ConfigService,
   private authService:AuthService,
    private router:Router,
    private sanitizer:DomSanitizer) {
     
  }
  ngOnInit(): void {
    this.loadConfiguration();
    this.isOutage = outage.outage.flag;
    if(this.isOutage){
      this.outage1 = this.sanitizer.bypassSecurityTrustHtml(outage.outage.outage1);
      this.outage2 = this.sanitizer.bypassSecurityTrustHtml(outage.outage.outage2); 
    }
    // original code
    // if(!this.cookieService.get('lat')){
      
    //   this.cookieService.set('lat','y', 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
    //   this.router.navigate(['/auth']);
    // }

    

    // let redirect:any = this.cookieService.get("redirect");
    // if(redirect){
    //   // console.log("redirect called--landing->"+redirect);
    //   // this.cookieService.set("redirect","");
    //   this.cookieService.set('redirect',"", 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
    //   this.router.navigateByUrl(redirect,{
    //     state:{}
    //   });
    // }
    
  }
  disclosureData : any;
  private loadConfiguration(): void {
    this.configService.loadConfig().subscribe(
      (data) => {
        this.configService.setConfig(data);
        //console.log('Configuration loaded:', data);
        this.disclosureData = this.sanitizer.bypassSecurityTrustHtml(`
          <span>${data.disclosureStatement}</span>
        `);
        console.log(this.disclosureData);
      },
      (error) => {
        console.error('Error loading configuration:', error);
      }
    );
  }


  // async ngAfterViewInit(): Promise<void> {
  //     this.engineService.getEngibeObj().then((eng)=>{
  //       this.engine = eng;
  //       this.searchInterface.initializeWithSearchEngine(this.engine).then(() => {
  //         if(!this.searchInterface.engine) {
  //           console.error('search engine not found');
  //           return;
  //         }
  //         else {
  //           this.setContext(this.searchInterface.engine);
  //         }
  //         this.searchInterface.executeFirstSearch();
  //       });
  //     });
  // }


  userId;
  ngAfterViewInit(){
    this.userId = this.authService.getUserId();
    this.engine = this.engineService.getEngibeObj();
      this.searchInterface.initializeWithSearchEngine(this.engine).then(() => {
        if(!this.searchInterface.engine) {
          console.error('search engine not found');
          return;
        }
        else {
          this.setContext(this.searchInterface.engine);
        }
        // this.searchInterface.executeFirstSearch();
      });

      // this.cookieService.set("lan","true");
      this.cookieService.set("lan","true", 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
      
      // this.cookieService.set("cs","");
      this.cookieService.set("cs","", 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');

    //   let redirect:any = this.cookieService.get("redirect");
    // if(redirect){
    //   // console.log("redirect called--landing->"+redirect);
    //   // this.cookieService.set("redirect","");
    //   this.cookieService.set('redirect',"", 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
    //   this.router.navigateByUrl(redirect,{
    //     state:{}
    //   });
    // }else{
      this.cookieService.set('redirect',"", 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');

      try{
        var rkymt = this.cookieService.get('rkymnt');
        if(rkymt ==='rkm'){
           this.router.navigateByUrl("/");
         }
      }catch(err){
        // console.log("err-->"+err);
      }

    // }
}


  onStateUpdate(){
    // // console.log("CALLED");
    // // console.log('Current query:', JSON.stringify(this.engine));
  }


  public setExpression(engine : SearchEngine) {

    const action = loadAdvancedSearchQueryActions(

      engine

    ).updateAdvancedSearchQueries({

      aq: '@source=Products',

    });

    engine.dispatch(action);

  }



 

  public setContext(engine : SearchEngine) {

    // const action = loadContextActions(engine).addContext({

    //   contextKey: 'userGroup',

    //   contextValue: 'sales',

    // });

    // engine.dispatch(action);

    // const action = loadContextActions(engine).addContext({
    //   contextKey: 'contentType',
    //   contextValue: 'Enclosures',
    // });

    // engine.dispatch(action);

  }

  // Custom Events


  customEventsAxonify()
  {
    this.eventLoggingService.logCustomEvent('Landing Page', { userId: this.cookieService.get('usi'),Action:'Click'},'Click on Axonify');

  }

  customEventsMore()
  {
    this.eventLoggingService.logCustomEvent('Landing Page',{ userId: this.cookieService.get('usi'),Action:'Click' },'Click on Learn more about Search Navigator');

  }

  customEventsGlobal()
  {
    this.eventLoggingService.logCustomEvent('Landing Page', { userId: this.cookieService.get('usi'),Action:'Click' },'Click on Global Scientific Content Congress Portal');

  }

  customEventsVISearch()
  {
    this.eventLoggingService.logCustomEvent('Landing Page',{ userId: this.cookieService.get('usi'),Action:'Click' },'Click on V&I Search Navigator Resource Center');

  }

  customEventsModel()
  {
    this.eventLoggingService.logCustomEvent('Landing Page',{ userId: this.cookieService.get('usi'),Action:'Click' },'Click on Model Repository');

  }

  customEventsGlobalAccessNetwork()
  {
    this.eventLoggingService.logCustomEvent('Landing Page',{ userId: this.cookieService.get('usi'),Action:'Click' },'Click on Global Access Network');

  }

  customEventsScited()
  {
    this.eventLoggingService.logCustomEvent('Landing Page',{ userId: this.cookieService.get('usi'),Action:'Click' },'Click on SCITED');

  }

  customEventsRWD()
  {
    this.eventLoggingService.logCustomEvent('Landing Page',{ userId: this.cookieService.get('usi'),Action:'Click' },'Click on RWDEx');

  }


  customEventsCore()
  {
    this.eventLoggingService.logCustomEvent('Landing Page',{ userId: this.cookieService.get('usi'),Action:'Click' },'Click on CORE Strategic Partnerships');

  }
  



}

